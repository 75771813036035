import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { get, findIndex } from "lodash";
import { ColourConstants } from "../orderBuilder/store/constants";
import testModel from "../../assets/img/ModelTest.png";
import Close from "@mui/icons-material/Close";
import { OrderStatus } from "../storeHome/store/constants";

import(/* webpackChunkName: "cart" */ "./cart.scss");

const Cart = ({ cartStatus, cartData, addOrderToCart, setIsStockOrder }) => {
  let history = useHistory();

  const [itemsInCart, setItemsInCart] = useState([]);
  const [cartItemsInLocalState, setCartItemsInLocalState] = useState([]);
  useEffect(() => {
    setItemsInCart(cartData);
  }, [cartData]);

  const startEmptyCart = () => {
    // If empty cart is clicked give warning to user
    if (confirm("Are you sure you want to clear your cart?")) {
      // If yes selected in alert, first remove cartData from local storage
      localStorage.removeItem("cartData");
      // Set local storage to an empty array to stop the site from logging you out
      localStorage.setItem("cartData", JSON.stringify([]));
      // Set cartdata in site wide redux to an empty array
      addOrderToCart([]);
      // Set cart data in state of component to an empty array
      setItemsInCart([]);

      setIsStockOrder(OrderStatus.NONE);
    }
  };

  const removeFromOrder = (itemCode, itemSide) => {
    // If remove item button is clicked give warning to user
    if (confirm("Are you sure you want to remove this item?")) {
      // If yes selected in alert, make copy of data in state
      let tempItemsInCart = [...itemsInCart];
      // use filter to remove the item that matches the itemCode passed in to function
      tempItemsInCart = tempItemsInCart.filter(function (value, index, arr) {
        if (value.Code != itemCode) {
          return value;
        } else {
          if (value.Side != itemSide) {
            return value;
          }
        }
      });
      // Remove cart data from local storage
      localStorage.removeItem("cartData");
      // Set cart data in local storage to updated array with item removed
      localStorage.setItem("cartData", JSON.stringify(tempItemsInCart));
      // Set the same array to cartData in redux
      addOrderToCart(tempItemsInCart);
      
      if(tempItemsInCart.length <= 0){
        setIsStockOrder(OrderStatus.NONE);
      }

      // Set the same array to state
      setItemsInCart(tempItemsInCart);
    }
  };

  useEffect(() => {
    if (itemsInCart) {
      setCartItemsInLocalState(itemsInCart);
    }
  }, [itemsInCart]);

  const checkout = () => {
    // If checkout was clicked move the user to order confirmation page
    history.push({ pathname: "/order-confirmation" });
  };

  const startChangeQuantity = (event, item) => {
    let tempQuantity = 1;
    if (event.target.value) {
      if(event.target.value > 1) {
        tempQuantity = event.target.value;
      }
      let tempItemsInCart = [...itemsInCart];
      // Find item index in array of items in cart by using the item code of the item passed into function
      var index = findIndex(tempItemsInCart, { Code: item.Code });
      let tempItem = item;
      // Update the quanitity to value inputed by user
      tempItem.Quantity = tempQuantity;
      // Replace item at index using native splice
      tempItemsInCart.splice(index, 1, tempItem);
      // Remove cart data from local storage
      localStorage.removeItem("cartData");
      // Set cart data in local storage to updated array with item removed
      localStorage.setItem("cartData", JSON.stringify(tempItemsInCart));
      // Set the same array to cartData in redux
      addOrderToCart(tempItemsInCart);
      // set updated array to state
      setItemsInCart(tempItemsInCart);
    }
  };

  return (
    <div className="cart-main-div">
      <Row className="pb-4 pt-4">
        <Col md={6}>
          <h1>Your Cart</h1>
        </Col>
        <Col xs={4}>
          {itemsInCart && itemsInCart.length > 0 && (
            <p className="float-right">QUANTITY</p>
          )}
        </Col>
        <Col xs={2}>
          {itemsInCart && itemsInCart.length > 0 && (
            <p className="float-right">REMOVE ITEM</p>
          )}
        </Col>
      </Row>
      {cartItemsInLocalState &&
        cartItemsInLocalState.map((item, index) => {
          if (!item) {
            return;
          }
          let totalOfItemInCart = itemsInCart.filter(function (
            value,
            index,
            arr
          ) {
            return value && item && value.Code == item.Code;
          }).length;
          if (item.Quantity) {
            // Set value for input box using the quantity value of item
            totalOfItemInCart = item.Quantity;
          }

          return (
            <Row
              key={index}
              className={`item-in-cart-row ${
                index == 0 ? "first-item-in-cart" : ""
              }`}
            >
              <Col xs={2} className="item-in-cart-img-div">
                {item.ImagePath && (
                  <img className="img-fluid" src={item.ImagePath} />
                )}
              </Col>
              <Col xs={4} className="item-in-cart-info">
                <div className="item-in-cart-info-outer-div">
                  <div className="item-in-cart-info-inner-div">
                    <p className="item-in-cart-name">
                      {item.Name} {item.Side}
                    </p>
                    <p>
                      <strong>Item Code:</strong> {item.Code}
                    </p>
                    <p>{item.ColourName && `Colour: ${item.ColourName}`}</p>
                    {item.specialInstructions && (
                      <p>
                        <strong>Special Instructions:</strong>{" "}
                        {item.specialInstructions}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={5}>
                <div className="item-in-cart-info-outer-div float-right quanitity-main-div-margin">
                  <div className="item-in-cart-info-inner-div">
                    <Form className="d-flex item-in-cart-quantity">
                      <Form.Group>
                        <div>
                          <Form.Control
                            type="number"
                            name="quantity"
                            value={totalOfItemInCart}
                            onChange={(event) =>
                              startChangeQuantity(event, item)
                            }
                            onBlur={(event) => startChangeQuantity(event, item)}
                            // min="1"
                            className="me-2"
                            aria-label="Stock"
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col xs={1} className="remove-item-in-cart-btn">
                <div className="item-in-cart-info-outer-div float-right">
                  <div className="item-in-cart-info-inner-div">
                    <Button
                      onClick={() => removeFromOrder(item.Code, item.Side)}
                    >
                      <Close />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      <Row className="pt-3">
        <Col xs={2}>
          {itemsInCart && itemsInCart.length > 0 && (
            <Button className="btn btn-secondary" onClick={startEmptyCart}>
              Empty Cart
            </Button>
          )}
        </Col>
        <Col xs={8}>
          <Link className="nav-link float-right" to="/">
            {"Continue Shopping >"}
          </Link>
        </Col>
        <Col xs={2}>
          {itemsInCart && itemsInCart.length > 0 && (
            <Button className="float-right" onClick={checkout}>
              Proceed to Checkout
            </Button>
          )}
          {itemsInCart && itemsInCart.length <= 0 && (
            <Button className="float-right" disabled>
              Proceed to Checkout
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

Cart.propTypes = {
  cartStatus: PropTypes.string,
  cartData: PropTypes.array,
  addOrderToCart: PropTypes.func,
};

export default Cart;
