import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { ProductsStatus } from './constants';

const productsStatus = createReducer({
  [actions.SET_PRODUCTS_STATUS]: (state, { payload }) => payload
}, ProductsStatus.IDLE);

const productsData = createReducer({
  [actions.SET_PRODUCTS_DATA]: (state, { payload }) => payload
}, ProductsStatus.IDLE);

const isStockOrder = createReducer({
  [actions.SET_IS_STOCK_ORDER]: (state, { payload }) => payload
}, ProductsStatus.IDLE);

export default combineReducers({
  productsStatus,
  productsData,
  isStockOrder
});
