import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

export const loadOrderConfirmation = create(actions.LOAD_ORDER_CONFIRMATION);
export const resetOrderNumber = create(actions.RESET_ORDER_NUMBER);
export const setOrderConfirmationStatus = create(actions.SET_ORDER_CONFIRMATION_STATUS);
export const setOrderConfirmationData = create(actions.SET_ORDER_CONFIRMATION_DATA);
export const setOrderNumber = create(actions.SET_ORDER_NUMBER);
export const setSentOrderConfirmationStatus = create(actions.SET_SENT_ORDER_CONFIRMATION_STATUS);
export const submitOrder = create(actions.SUBMIT_ORDER);
