import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from "react-bootstrap";
import { ResetPasswordStatus } from './store/constants';
import Loading from '../../components/Loading/Loading';
import Cookies from 'universal-cookie';
import { Link, useParams } from "react-router-dom";
import config from '../../config.js'
import(/* webpackChunkName: "resetPassword" */'./resetPassword.scss');

const ResetPassword = ({
  resetPasswordStatus,
  startResetPassword,
}) => {
  const [passwordMissmatch, setPasswordMissmatch] = useState(false);
  const [mailToEmail, setMailToEmail] = useState("");
  const { code } = useParams();

  useEffect(() => {
    console.log("ResetPassword.js");
    //console.log(code);
    if(config.siteName && config.siteName==="PHONAK"){
      setMailToEmail("nzorders@phonak.com");
    }
    else if (config.siteName && config.siteName==="UNITRON"){
      setMailToEmail("nzorders@unitron.com");
    }
    
    
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    var [ password, passwordConfirm ] = document.forms[0];

    if (password.value === passwordConfirm.value) {
      setPasswordMissmatch(false);
      startResetPassword(password.value, code);
    }
    else {
      setPasswordMissmatch(true);
    }
  };

  if (resetPasswordStatus === ResetPasswordStatus.LOADING) {
    return (
      <Col>
        <div className="Auth-form-container reset-password-loading">
          <Loading />
        </div>
      </Col>
    )
  }

  if (resetPasswordStatus === ResetPasswordStatus.SUCCESS) {
    return (
      <Col xs={12}>
        <Row>
          <Col xs={4}></Col>
          <Col xs={4}>
            <div className="reset-password-success">
              <h2>Password reset successfully</h2>
              <p><Link to="/login">Go back</Link> and login with your new password to access the site</p>
            </div>
          </Col>
          <Col xs={4}></Col>
        </Row>
      </Col>
    )
  }

  return (
    <Col>
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Reset Password</h3>
            <div className="form-group mt-3">
              <label>New password</label>
              <input
                type="password"
                name="password"
                className="form-control mt-1"
                placeholder="Enter new password"
                required
              />
            </div>
            <div className="form-group mt-3">
              <label>Confirm new password</label>
              <input
                type="password"
                name="confirmPassword"
                className="form-control mt-1"
                placeholder="Confirm new password"
                required
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            {resetPasswordStatus === ResetPasswordStatus.ERROR &&
              <div className='alert alert-danger mt-3'>
                <p>An error occured.</p>
                <p>Please <Link to="/forgot-password">click here</Link> and follow the prompts to access the site and, if this doesn't work, please <a href={`mailto:${mailToEmail}`}>contact us</a> for support</p>
              </div>
            }
            {passwordMissmatch &&
              <div className='alert alert-danger mt-3'>
              <p>Passwords do not match.</p>
              <p>Please try again.</p>
            </div>
            }
          </div>
        </form>
      </div>
    </Col>
  )
};

ResetPassword.propTypes = {
  startResetPassword: PropTypes.func,
  resetPasswordStatus: PropTypes.string,
}

export default ResetPassword;
