import { connect } from 'react-redux';

import OrderConfirmation from './orderConfirmation';
import { getOrderConfirmationStatus, getOrderConfirmationData, getSentOrderConfirmationStatus, getOrderNumber } from './store/selectors';
import { getCartData } from '../orderBuilder/store/selectors';
import { getProfileData } from '../login/store/selectors';
import { addOrderToCart } from '../orderBuilder/store/action-creators';
import { submitOrder, loadOrderConfirmation, resetOrderNumber } from './store/action-creators';
import { getIsStockOrder } from '../storeHome/store/selectors';
import { setIsStockOrder } from '../storeHome/store/action-creators';


const mapStateToProps = (state) => ({
  orderConfirmationStatus: getOrderConfirmationStatus(state),
  orderConfirmationData: getOrderConfirmationData(state),
  sentOrderConfirmationStatus: getSentOrderConfirmationStatus(state),
  orderNumber: getOrderNumber(state),
  cartData: getCartData(state),
  profileData: getProfileData(state),
  isStockOrder: getIsStockOrder(state),
});

const mapDispatchToProps = {
  submitOrder,
  addOrderToCart,
  loadOrderConfirmation,
  resetOrderNumber,
  setIsStockOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);