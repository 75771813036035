import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { OrderStatus } from "../../store/constants";
import testModel from "../../../../assets/img/ModelTest.png";
import(/* webpackChunkName: "product-cards" */ "./productCard.scss");

const ProductCard = ({ product, index, isStockOrder }) => {
  const [cardClassName, setCardClassName] = useState();
  useEffect(() => {
    let tempCardClassName = "";
    if (index % 4 == 1) {
      //console.log("1", index);
      tempCardClassName = "middle-left-family-category-card"
    }
    else if (index % 4 == 2) {
      //console.log("2", index);
      tempCardClassName = "middle-right-family-category-card"
    }
    setCardClassName(tempCardClassName);
  }, []);
  
  if (isStockOrder == OrderStatus.STOCK_ORDER) {

    return (
      <Col xs={12} md={6} lg={4} xl={3} className={`${cardClassName} mb-3`}>
        <div className="family-category-card disabled-link">
          <div>
            <h3 className="family-category-category-title">{product.Name}</h3>
          </div>
          <div className="family-category-img">
            <span className="helper">
              <img src={product.ImagePath} alt={product.Name} />
            </span>
          </div>
          <div>
            <p className="btn">Order Now</p>
          </div>
        </div>
      </Col>
    );
  }
  else {
    return (
      <Col xs={12} md={6} lg={4} xl={3} className={`${cardClassName} mb-3`}>
        <Link to={`/store/${product.ParentCode}/${product.Code}`}>
          <div className="family-category-card test">
            <div>
              <h3 className="family-category-category-title">{product.Name}</h3>
            </div>
            <div className="family-category-img">
              <span className="helper">
                <img src={product.ImagePath} alt={product.Name} />
              </span>
            </div>
            <div>
              <p className="btn">Order Now</p>
            </div>
          </div>
        </Link>
      </Col>
    )
  }
};

export default ProductCard;
