// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import { SUBMIT_ORDER, LOAD_ORDER_CONFIRMATION, RESET_ORDER_NUMBER } from './actions';
import { get } from 'lodash';
import { OrderConfirmationStatus } from './constants';
import api from '../../../utilities/api';
import moment from 'moment';

export function* rootSaga() {
  yield takeLatest(SUBMIT_ORDER, submitOrder);
  yield takeLatest(LOAD_ORDER_CONFIRMATION, loadOrderConfirmation);
  yield takeLatest(RESET_ORDER_NUMBER, resetOrderNumber);
  
}

export function* loadOrderConfirmation() {
  try {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.LOADING));

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken? This will logout if there is no token. Handled in the private layout
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    const response = yield call(api.get, '/api/order/timelines', {headers:headerParams});
    yield put(actionCreators.setOrderConfirmationData(response.data));

    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}

export function* resetOrderNumber() {
  try {
    // yield console.log("reset order number STARTT")
    yield put(actionCreators.setOrderNumber(""));
    // yield console.log("reset order number END")

  }
  catch(error) {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}

export function* submitOrder(data) {
  try {
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.LOADING));
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    // console.log('data passed in to saga', data);

    const orderId = get(data, "", 0);
    const orderNumber = get(data, "", "");
    const userId = get(data, "", 0);
    const organisationCode = get(data, "3", "");
    const firstName = get(data, "5", "");
    const lastName = get(data, "6", "");
    const orderType = get(data, "16", "");
    const orderDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const ponumber = get(data, "1", "");
    const dateOfBirth = get(data, "7", "");
    const requiredByDate = get(data, "8", "");
    const hafsOrder = get(data, "9", "");
    const tamperProofRequired = get(data, "10", "");
    const VAClient = get(data, "11", false);
    const isStockOrder = get(data, "12", false);
    const files = get(data, "13", []);
    const siteId = get(data, "4", 0);
    const customShippingInfo = get(data, "0", "");
    const gensolveId = get(data, "", "");
    const orderNotes = get(data, "", ""); 
    const specialInstructions = get(data, "2", "");
    const discountCode = get(data, "14", "");
    const discountOption = get(data, "15", "");
    const trackingStatus = get(data, "", "");
    const trackingNumber = get(data, "", "");
    const deliveryDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const invoiceNumber = get(data, "", "");
    const invoiceDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const invoiceAmount = get(data, "", 0);
    const orderItems = get(data, "payload", []);

    console.log("orderDate", orderDate.toString());
    console.log("isStockOrder", isStockOrder.toString());
    console.log("orderItems", JSON.stringify(orderItems));

    // const body = {
    //   OrderId: orderId,
    //   OrderNumber: orderNumber,
    //   UserId: userId,
    //   OrganisationCode: organisationCode,
    //   ClientFirstName: firstName,
    //   ClientLastName: lastName,
    //   OrderType: orderType,
    //   OrderDate: orderDate.toString(),
    //   Ponumber: ponumber,
    //   ClientDateOfBirth: dateOfBirth,
    //   RequiredByDate: requiredByDate,
    //   SiteCode: siteId,
    //   CustomShippingInfo: customShippingInfo,
    //   GensolveId: gensolveId,
    //   OrderNotes: orderNotes,
    //   TrackingStatus: trackingStatus,
    //   TrackingNumber: trackingNumber,
    //   DeliveryDate: deliveryDate,
    //   InvoiceNumber: invoiceNumber,
    //   InvoiceDate: invoiceDate,
    //   InvoiceAmount: invoiceAmount,
    //   OrderItems: JSON.stringify(orderItems), 
    //   SpecialInstructions: specialInstructions,
    //   IsHafs: hafsOrder,
    //   TamperProofRequired: tamperProofRequired,
    //   IsStock: isStockOrder.toString()
    // };

    // console.log(body);
    var bodyFormData = new FormData();
    bodyFormData.append('ClientDateOfBirth', dateOfBirth);
    bodyFormData.append('ClientFirstName', firstName);
    bodyFormData.append('ClientLastName', lastName);
    bodyFormData.append('CustomShippingInfo', customShippingInfo);
    bodyFormData.append('DeliveryDate', deliveryDate);
    files.forEach(function(file, index) {
      bodyFormData.append('Files', file);
    });
    // bodyFormData.append('Files', files);
    bodyFormData.append('GensolveId', gensolveId);
    bodyFormData.append('InvoiceAmount', invoiceAmount);
    bodyFormData.append('InvoiceDate', invoiceDate);
    bodyFormData.append('InvoiceNumber', invoiceNumber);
    bodyFormData.append('IsHafs', hafsOrder.toString());
    bodyFormData.append('IsStock', isStockOrder.toString());
    bodyFormData.append('OrderId', orderId);
    bodyFormData.append('OrderItems', JSON.stringify(orderItems));
    bodyFormData.append('OrderNotes', orderNotes);
    bodyFormData.append('OrderNumber', orderNumber);
    bodyFormData.append('OrderType', orderType);
    bodyFormData.append('OrganisationCode', organisationCode);
    bodyFormData.append('OrderDate', orderDate.toString());
    bodyFormData.append('Ponumber', ponumber);
    bodyFormData.append('RequiredByDate', requiredByDate);
    bodyFormData.append('SiteCode', siteId);
    bodyFormData.append('SpecialInstructions', specialInstructions);
    bodyFormData.append('TamperProofRequired', tamperProofRequired.toString());
    bodyFormData.append('TrackingNumber', trackingNumber);
    bodyFormData.append('TrackingStatus', trackingStatus);
    bodyFormData.append('IsVeteransAffairsClient', VAClient);
    bodyFormData.append('DiscountCode', discountCode);
    bodyFormData.append('DiscountOption', discountOption);
    bodyFormData.append('UserId', userId);

    const response = yield call(api.post, '/api/order', bodyFormData, {headers:headerParams});
    // console.log("order confirmation response", response);
    if(response.data){
      yield put(actionCreators.setOrderNumber(response.data));
      // console.log("setOrderNumber");
    }
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.SUCCESS));
  }
  catch(error) {
    // console.log("order confirmation response", error);
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}