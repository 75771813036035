import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import testModel from "../../../../assets/img/ModelTest.png";
import(/* webpackChunkName: "product-cards" */ "./stockOrderProductCard.scss");

const StockOrderProductCard = ({ product, index }) => {
  const [ cardClassName, setCardClassName ] = useState();
  useEffect(() => {
    let tempCardClassName = "";
    if(index % 4 == 1) {
      //console.log("1", index);
      tempCardClassName =  "middle-left-stock-orders-card" 
    } 
    else if (index % 4 == 2) {
      //console.log("2", index);
      tempCardClassName = "middle-right-stock-orders-card"
    }
    setCardClassName(tempCardClassName);
  }, []);

  return (
    <Col xs={12} md={6} lg={4} xl={3} className={`${cardClassName} mb-3`}>
      <Link to={`/store/StockOrders/${product.Code}`}>
      <div className="stock-orders-card">
        <div>
          <h3 className="stock-orders-category-title">{product.Name}</h3>
        </div>
        <div className="stock-orders-img">
          <span className="helper">
            <img src={product.ImagePath} />
          </span>
        </div>
        <div>
          <p className="btn">Order Now</p>
        </div>
      </div>
      </Link>
    </Col>
  );
};

export default StockOrderProductCard;
