// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from "redux-saga/effects";
import * as actionCreators from "./action-creators";
import Cookies from "universal-cookie";
import {
  LOAD_ORDER_BUILDER_DATA,
  ADD_ORDER_TO_CART,
  LOAD_CONFIGURATION_GROUP_DATA,
  LOAD_CUSTOM_OPTIONS_GROUP_DATA,
} from "./actions";
import {
  OrderBuilderStatus,
  CartStatus,
  ConfigurationGroupStatus,
  CustomOptionsStatus,
} from "./constants";
import { get, map } from "lodash";
import api from "../../../utilities/api";

export function* rootSaga() {
  yield takeLatest(LOAD_ORDER_BUILDER_DATA, loadOrderBuilder);
  yield takeLatest(LOAD_CONFIGURATION_GROUP_DATA, loadConfigurationGroup);
  yield takeLatest(LOAD_CUSTOM_OPTIONS_GROUP_DATA, loadCustomOptionsGroup);
  yield takeLatest(ADD_ORDER_TO_CART, addOrderToCart);
}

export function* loadOrderBuilder(dataObject) {
  try {
    yield put(actionCreators.setOrderBuilderStatus(OrderBuilderStatus.LOADING));
    const categoryId = get(dataObject, "payload", "");
    //This is model ID if pulled through from earpieces screen
    const productId = get(dataObject, "0", "");

    const cookies = new Cookies();
    const token = cookies.get("LoginToken");
    const headerParams = {
      Authorization: `Bearer ${token}`,
    };
    let response = {};
    //console.log(categoryId, productId);

    // Checks what family type the item is and call to respective API endpoint
    if (categoryId === "BTE_RIC") {
      response = yield call(
        api.get,
        `/api/RICBuilder/GetBuilderData/${productId}`,
        { headers: headerParams }
      );
    } else if (categoryId === "ITE") {
      response = yield call(
        api.get,
        `/api/ITEBuilder/GetBuilderData/${productId}`,
        { headers: headerParams }
      );
    } else if (categoryId === "Earpiece") {
      if(productId.Code){
        response = yield call(
          api.get,
          // `/api/earpiece/${productId.ModelCode}`,
          `/api/earpiece/${productId.Code}`,
          { headers: headerParams }
        );
      }
      else {
        response = yield call(
          api.get,
          // `/api/earpiece/${productId.ModelCode}`,
          `/api/earpiece/unknown`,
          { headers: headerParams }
        );
      }
    }
    else if (categoryId === "AccRgrChg") {
      if(productId.Code){
        response = yield call(
          api.get,
          // `/api/earpiece/${productId.ModelCode}`,
          `/api/accessory/${productId.Code}`,
          { headers: headerParams }
        );
      }
      else {
        response = yield call(
          api.get,
          // `/api/earpiece/${productId.ModelCode}`,
          `/api/accessory/unknown`,
          { headers: headerParams }
        );
      }
    }
    else if (categoryId === "SerenityC") {
      response = yield call(
        api.get,
        // `/api/earpiece/${productId.ModelCode}`,
        `/api/CustomHearingProtection`,
        { headers: headerParams }
      );
    }
    // Saves order builder data
    yield put(actionCreators.setOrderBuilderData(response.data));
    yield put(actionCreators.setOrderBuilderStatus(OrderBuilderStatus.SUCCESS));
  } catch (error) {
    yield put(actionCreators.setOrderBuilderStatus(OrderBuilderStatus.ERROR));
  }
}

export function* loadConfigurationGroup(data) {
  try {
    yield put(actionCreators.setConfigurationGroupStatus(ConfigurationGroupStatus.LOADING));
    const side = get(data, "payload", "");
    const configGroupId = get(data, "0", "");
    const configGroupDataObject = get(data, "1", {});
    const isEarmolds = get(data, "2", "");

    // configGroupDataObject is a big object with configuration data for different model types. Keys are the relatedItemIds
    // if id not found in configGroupDataObject then add to obj from api
    let tempConfigGroupData = get(configGroupDataObject, configGroupId, false);
  
    if (!tempConfigGroupData) {
      const cookies = new Cookies();
      const token = cookies.get("LoginToken");
      const headerParams = {
        Authorization: `Bearer ${token}`,
      };
      let response = [];

      if (isEarmolds === "earmolds") {
        // console.log("before config options response", configGroupId)
  
        response = yield call(
          api.get,
          `/api/earpiece/${configGroupId}/configurationoptions`,
          { headers: headerParams }
        );
        // console.log("after config options response", response)
      } else {
  
        response = yield call(
          api.get,
          `/api/configurationgroup/${configGroupId}/getfull`,
          { headers: headerParams }
        );
      }


      const dataFromAPI = response.data;
      //Set new data into object and set it back into redux
      configGroupDataObject[`${configGroupId}`] = dataFromAPI;
      yield put(actionCreators.setConfigurationGroupData(configGroupDataObject));

      //If both sides are selected then add this data to both sides, if not set it to the side selected.
      if (side == "B") {
        yield put(actionCreators.setConfigurationGroupLeft(dataFromAPI));
        yield put(actionCreators.setConfigurationGroupRight(dataFromAPI));
      } else if (side == "R") {
  
        yield put(actionCreators.setConfigurationGroupRight(dataFromAPI));
      } else if (side == "L") {
  
        yield put(actionCreators.setConfigurationGroupLeft(dataFromAPI));
      }
    } else if (tempConfigGroupData) {
      // if ID is found in config object set it to the sides selected exactly like section directly above
      if (side == "B") {
  
        yield put(actionCreators.setConfigurationGroupLeft(tempConfigGroupData));
        yield put(actionCreators.setConfigurationGroupRight(tempConfigGroupData));
      } else if (side == "R") {
  
        yield put(actionCreators.setConfigurationGroupRight(tempConfigGroupData));
      } else if (side == "L") {
  
        yield put(actionCreators.setConfigurationGroupLeft(tempConfigGroupData));
      }
    }

    yield put(actionCreators.setConfigurationGroupStatus(ConfigurationGroupStatus.SUCCESS));
  } catch (error) {
    yield put(actionCreators.setConfigurationGroupStatus(ConfigurationGroupStatus.ERROR));
  }
}

export function* loadCustomOptionsGroup(data) {
  try {
    //This section functions very similarly to section above
    yield put(actionCreators.setCustomOptionsGroupStatus(CustomOptionsStatus.LOADING));
    // console.log("loadCustomOptionsGroup - ", data);

    const side = get(data, "payload", "");
    const modelCode = get(data, "0", "");
    const customOptionsDataObject = get(data, "1", {});
    //console.log("CUSTOM OPTIONS SAGA", side, modelCode, customOptionsDataObject)
    // if id not in customOptionsDataObject then add to obj from api
    let tempCustomOptionsData = get(customOptionsDataObject, { modelCode }, false);

    if (!tempCustomOptionsData) {
      const cookies = new Cookies();
      const token = cookies.get("LoginToken");
      const headerParams = {
        Authorization: `Bearer ${token}`,
      };

      if (modelCode) {
        // console.log(side, "before custom options call - ", modelCode);
        const response = yield call(
          api.get,
          `/api/modeltype/${modelCode}/customOptions`,
          { headers: headerParams }
        );
        // console.log(side, "after custom options call - ", response);
        const dataFromAPI = response.data;
        let customOptionsGroupDataObject = {};
        customOptionsGroupDataObject[`${modelCode}`] = dataFromAPI;

        yield put(
          actionCreators.setCustomOptionsGroupData(customOptionsGroupDataObject)
        );
        if (side == "B") {
          yield put(actionCreators.setCustomOptionsGroupLeft(dataFromAPI));
          yield put(actionCreators.setCustomOptionsGroupRight(dataFromAPI));
        } else if (side == "R") {
          yield put(actionCreators.setCustomOptionsGroupRight(dataFromAPI));
        } else if (side == "L") {
          yield put(actionCreators.setCustomOptionsGroupLeft(dataFromAPI));
        }
      }
    } else if (tempCustomOptionsData) {
      if (side == "B") {
        yield put(
          actionCreators.setCustomOptionsGroupLeft(tempCustomOptionsData)
        );
        yield put(
          actionCreators.setCustomOptionsGroupRight(tempCustomOptionsData)
        );
      } else if (side == "R") {
        yield put(
          actionCreators.setCustomOptionsGroupRight(tempCustomOptionsData)
        );
      } else if (side == "L") {
        yield put(
          actionCreators.setCustomOptionsGroupLeft(tempCustomOptionsData)
        );
      }
    }

    yield put(
      actionCreators.setCustomOptionsGroupStatus(CustomOptionsStatus.SUCCESS)
    );
  } catch (error) {
    yield put(
      actionCreators.setCustomOptionsGroupStatus(CustomOptionsStatus.ERROR)
    );
  }
}

export function* addOrderToCart(orderData) {
  try {
    // Add orderDataArray to cart, set a delay as this happens almost instantly and is jarring for a user.
    yield put(actionCreators.setCartStatus(CartStatus.LOADING));
    yield delay(500);
    const orderDetails = get(orderData, "payload", []);
    // There are issues with null value items being added to an array
    const orderDetailsWithNullsRemoved = _.compact(orderDetails)

    yield put(actionCreators.setCartData(orderDetailsWithNullsRemoved));
    yield put(actionCreators.setCartStatus(CartStatus.SUCCESS));
  } catch (error) {
    yield put(actionCreators.setCartStatus(CartStatus.ERROR));
  }
}
