import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get, find } from "lodash";
import(
  /* webpackChunkName: "order-builder-repair-family-model" */ "./repairFamilyModel.scss"
);

import testModel from "../../../../assets/img/ModelTest.png";

const RepairFamilyModel = ({
  productsData,
  selectedModel,
  setSelectedModel,
  initialProductCategory,
  rightSelectedModel,
  setRightSelectedModel,
  leftSelectedModel,
  setLeftSelectedModel,
  initialModelCode
}) => {
  const [ricProductsArray, setRICProductsArray] = useState();
  const [rightSelectedFamily, setRightSelectedFamily] = useState();
  const [leftSelectedFamily, setLeftSelectedFamily] = useState();
  const [rightModelsArray, setRightModelsArray] = useState([]);
  const [leftModelsArray, setLeftModelsArray] = useState([]);
  const [rightSelectedLocalModel, setRightSelectedLocalModel] = useState();
  const [leftSelectedLocalModel, setLeftSelectedLocalModel] = useState();

  useEffect(() => {
    if (productsData) {
      // Filters products to only show RIC and BTE items for the family and mode dropdowns
      let tempRICProductsArray = [...productsData];

      tempRICProductsArray = find(tempRICProductsArray, {
        Name: "BTE/RICs",
      });
      tempRICProductsArray = get(
        tempRICProductsArray,
        "InverseParentCodeNavigation",
        []
      );
      setRICProductsArray(tempRICProductsArray);
      // if(initialProductCategory !="Earpiece" && initialProductCategory !="AccRgrChg"  && initialProductCategory !="Repair")
      // {
      //   setSelectedFamily(initialProductCategory);
      // }

    }
  }, [productsData]);

  useEffect(() => {
    // On family change update the model dropdown
    if (rightSelectedFamily && rightSelectedFamily != "Unknown") {
      const modelsArray = find(ricProductsArray, { Code: rightSelectedFamily }, {});
      //console.log("On family change - rightSelectedFamily",rightSelectedFamily)
      //console.log(modelsArray.SoModelTypes);
      setRightModelsArray(modelsArray.SoModelTypes);
    } else {
      setRightModelsArray([]);
      setRightSelectedModel("Unknown");
    }
  }, [rightSelectedFamily]);

  useEffect(() => {
    // On family change update the model dropdown
    if (leftSelectedFamily && leftSelectedFamily != "Unknown") {
      const modelsArray = find(ricProductsArray, { Code: leftSelectedFamily }, {});
      //console.log("On family change - leftSelectedFamily",leftSelectedFamily)
      //console.log(modelsArray.SoModelTypes);
      setLeftModelsArray(modelsArray.SoModelTypes);
    } else {
      setLeftModelsArray([]);
      setLeftSelectedModel("Unknown");
    }
  }, [leftSelectedFamily]);

  const handleRightFamilyChange = (event) => {
    const family = event.target.value;
    setRightSelectedFamily(family);
  };

  const handleLeftFamilyChange = (event) => {
    const family = event.target.value;
    setLeftSelectedFamily(family);
  };

  const handleRightModelChange = (event) => {
    // On model dropdown change update selected model in both local state and item builder state.
    const model = event.target.value;
    setRightSelectedLocalModel(model);

    if (model != "Unknown") {
      const modelObj = find(rightModelsArray, { Code: model }, {});
      setRightSelectedModel(modelObj);
    }
    else {
      setRightSelectedModel("Unknown");
    }
  };

  const handleLeftModelChange = (event) => {
    // On model dropdown change update selected model in both local state and item builder state.
    const model = event.target.value;
    setLeftSelectedLocalModel(model);

    if (model != "Unknown") {
      const modelObj = find(leftModelsArray, { Code: model }, {});
      setLeftSelectedModel(modelObj);
    }
    else {
      setLeftSelectedModel("Unknown");
    }
  };

  return (
    <div className="family-model-dropdowns">
      <Row>
        <Col md={6}>
          <Row>
            <Col xs={12}>
              <h2 style={{ color: "#222222" }}>Right Ear:</h2>
            </Col>
            <Col xs={6}>
              <div className="select">
                <p>Select Family</p>
                <select value={rightSelectedFamily} onChange={handleRightFamilyChange}>
                  <option value="Unknown">Unknown</option>
                  {ricProductsArray &&
                    ricProductsArray.length > 0 &&
                    ricProductsArray.map((product, index) => {
                      return (
                        <option key={index} value={product.Code}>
                          {product.Name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </Col>
            <Col xs={6}>
              <div className="select">
                <p>Select Model</p>
                <select value={rightSelectedLocalModel} onChange={handleRightModelChange}>
                  <option value="Unknown">Unknown</option>
                  {rightModelsArray &&
                    rightModelsArray.length > 0 &&
                    rightModelsArray.map((product, index) => {
                      return (
                        <option key={index} value={product.Code}>
                          {product.Name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col xs={12}>
              <h2 style={{ color: "#222222" }}>Left Ear:</h2>
            </Col>
            <Col xs={6}>
              <div className="select">
                <p>Select Family</p>
                <select value={leftSelectedFamily} onChange={handleLeftFamilyChange}>
                  <option value="Unknown">Unknown</option>
                  {ricProductsArray &&
                    ricProductsArray.length > 0 &&
                    ricProductsArray.map((product, index) => {
                      return (
                        <option key={index} value={product.Code}>
                          {product.Name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </Col>
            <Col xs={6}>
              <div className="select">
                <p>Select Model</p>
                <select value={leftSelectedLocalModel} onChange={handleLeftModelChange}>
                  <option value="Unknown">Unknown</option>
                  {leftModelsArray &&
                    leftModelsArray.length > 0 &&
                    leftModelsArray.map((product, index) => {
                      return (
                        <option key={index} value={product.Code}>
                          {product.Name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RepairFamilyModel;
