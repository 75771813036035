import { combineReducers } from "redux";
import { routerReducer as router  } from "react-router-redux";

import { getPreloadedState } from "./localStorage";
import dashboard from '../modules/dashboard/store/reducers';
import orderBuilder from '../modules/orderBuilder/store/reducers';
import relatedProducts from '../modules/relatedProducts/store/reducers';
import storeHome from '../modules/storeHome/store/reducers';
import login from '../modules/login/store/reducers';
import profile from '../modules/profile/store/reducers';
import orderConfirmation from '../modules/orderConfirmation/store/reducers';
import repairCheckout from '../modules/repairCheckout/store/reducers';
import forgotPassword from '../modules/forgotPassword/store/reducers';
import resetPassword from '../modules/resetPassword/store/reducers';
import orderDetail from '../modules/orderDetail/store/reducers';
import administrators from '../modules/administrators/store/reducers';
import organisations from '../modules/organisations/store/reducers';
import addEditOrganisation from '../modules/addEditOrganisation/store/reducers';
import sites from '../modules/sites/store/reducers';
import changePassword from '../modules/changePassword/store/reducers';

const modules = combineReducers({
  dashboard,
  orderBuilder,
  storeHome,
  login,
  profile,
  relatedProducts,
  orderConfirmation,
  // repairCheckout,
  forgotPassword,
  resetPassword,
  orderDetail,
  administrators,
  organisations,
  addEditOrganisation,
  sites,
  changePassword
});

export default (state = getPreloadedState(), action) => {
  return {
    routing: router(state.routing, action),
    modules: modules(state.modules, action),
  };
};