import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { merge, get, find } from "lodash";
import { Row, Col } from "react-bootstrap";
//Components
import Loading from "../../../../components/Loading/Loading";
import SelectSides from "../SelectSides/SelectSides";
import EarImpression from "../EarImpression/EarImpression";
import ITEModel from "../ITEModel/ITEModel";
import ITEShellStyle from "../ITEShellStyle/ITEShellStyle";
import ITEShellColour from "../ITEShellColour/ITEShellColour";
import ITECustomOptions from "../ITECustomOptions/ITECustomOptions";

//Constants
import { CartStatus, OrderBuilderStatus } from "../../store/constants";
import { OrderStatus } from "../../../storeHome/store/constants";

import(
  /* webpackChunkName: "custom-hearing-item-builder" */ "./customHearingItemBuilder.scss"
);

const CustomHearingItemBuilder = ({
  cartStatus,
  productsData,
  addOrderToCart,
  orderBuilderStatus,
  orderBuilderData,
  cartData,
  configurationGroupData,
  configurationGroupLeft,
  configurationGroupRight,
  loadConfigurationGroup,
  loadCustomOptionsGroup,
  customOptionsGroupData,
  customOptionsGroupLeft,
  customOptionsGroupRight,
  loadOrderBuilder,
  setIsStockOrder,
}) => {
  let history = useHistory();
  const { categoryId, productId, itemId } = useParams();
  const initialProductCategory = productId;
  const initialModelCode = itemId;

  //State used on this page. Split into groups of components
  const [selectedModel, setSelectedModel] = useState();

  const [leftSelected, setLeftSelected] = useState(false);
  const [rightSelected, setRightSelectedSides] = useState(false);

  const [earImpressionForOrder, setEarImpressionForOrder] = useState(false);

  const [iteModelForOrder, setITEModelForOrder] = useState();
  const [iteModelTypeCodes, setITEModelTypeCodes] = useState();
  const [iteModelLinked, setITEModelLinked] = useState(true);

  const [iteShellStyleLeft, setITEShellStyleLeft] = useState();
  const [iteShellStyleRight, setITEShellStyleRight] = useState();

  const [activeRightEarPiecesReceiver, setActiveRightEarPiecesReceivers] =
    useState();
  const [activeLeftEarPiecesReceiver, setActiveLeftEarPiecesReceivers] =
    useState();

  const [coloursLeft, setColoursLeft] = useState();
  const [coloursRight, setColoursRight] = useState();

  const [lengthsLeft, setLengthsLeft] = useState();
  const [lengthsRight, setLengthsRight] = useState();

  const [powerLevelRight, setPowerLevelRight] = useState();
  const [powerLevelLeft, setPowerLevelLeft] = useState();

  const [iteShellColourLeft, setITEShellColourLeft] = useState();
  const [iteShellColourRight, setITEShellColourRight] = useState();
  const [activeRightITEShellColour, setActiveRightITEShellColour] = useState();
  const [activeLeftITEShellColour, setActiveLeftITEShellColour] = useState();

  const [iteFaceplateColourLeft, setITEFaceplateColourLeft] = useState();
  const [iteFaceplateColourRight, setITEFaceplateColourRight] = useState();
  const [activeRightITEFaceplate, setActiveRightITEFaceplate] = useState();
  const [activeLeftITEFaceplate, setActiveLeftITEFaceplate] = useState();

  const [errorMessages, setErrorMessages] = useState([]);
  const [errorMessageRight, setErrorMessageRight] = useState("");
  const [errorMessageLeft, setErrorMessageLeft] = useState("");

  const [iteCustomOptionsForOrder, setITECustomOptionsForOrder] = useState({
    right: [],
    left: [],
  });

  const [iteConfigurationGroupLeft, setITEConfigurationGroupLeft] = useState(
    {}
  );
  const [iteConfigurationGroupRight, setITEConfigurationGroupRight] = useState(
    {}
  );

  const [selectedShellStyleRight, setSelectedShellStyleRight] = useState("");
  const [selectedShellStyleLeft, setSelectedShellStyleLeft] = useState("");

  const [currentCartForOrderSummary, setCurrentCartForOrderSummary] =
    useState();

  const [orderItems, setOrderItems] = useState();

  const [selectedModelRight, setSelectedModelRight] = useState("");
  const [selectedModelLeft, setSelectedModelLeft] = useState("");

  //Loads data on component mount
  useEffect(() => {
    if (loadOrderBuilder && productsData) {
      loadOrderBuilder("SerenityC", "SerenityC");
    }
  }, [productsData]);

  //Gets data from object returned from API and sets model types to state for use in a component.
  useEffect(() => {
    if (orderBuilderData) {
      const earpieceModels = get(orderBuilderData, "Items", []);
      setITEModelTypeCodes(earpieceModels);
    }
  }, [orderBuilderData]);

  //Reset options effected by model type when switching product category
  useEffect(() => {
    if (iteModelForOrder) {
      if (!iteModelForOrder.right || !iteModelForOrder.left) {
        setITEConfigurationGroupLeft({});
        setITEConfigurationGroupRight({});
      }
    }

    //Set configurationGroupLeft and configurationGroupRight options
    setITEConfigurationGroupLeft(configurationGroupLeft);
    setITEConfigurationGroupRight(configurationGroupRight);
  }, [configurationGroupLeft, configurationGroupRight, selectedModel, iteModelForOrder]);

  //If product was successfully added to cart move user to related-products page.
  useEffect(() => {
    if (cartStatus === CartStatus.SUCCESS && orderItems) {
      history.push({
        pathname: `/product-summary/${orderItems[0].Code}`,
        state: { productsForCart: currentCartForOrderSummary },
      });
    }
  }, [cartStatus]);

  const startAddOrderToCart = () => {
    setErrorMessages([]);
    var tempErrorMessages = [];

    setErrorMessageRight("");
    setErrorMessageLeft("");
    if (!rightSelected && !leftSelected) {
      tempErrorMessages.push("Select at least one side.");
    }

    if (!iteModelForOrder) {
      tempErrorMessages.push("Please select a model");
    }

    if (earImpressionForOrder.left) {
      if (earImpressionForOrder.left.type === "impressionOnFile") {
        if (!earImpressionForOrder.left.serialNumber) {
          tempErrorMessages.push("You selected to use an impression on file for the left ear but the serial number field is empty. Please enter a serial number.");
        }
      }
    }
    
    if (earImpressionForOrder.right) {
      if (earImpressionForOrder.right.type === "impressionOnFile") {
        if (!earImpressionForOrder.right.serialNumber) {
          tempErrorMessages.push("You selected to use an impression on file for the right ear but the serial number field is empty. Please enter a serial number.");
        }
      }
    }
    
    if (tempErrorMessages.length > 0) {
      setErrorMessages(tempErrorMessages);
      return;
    }

    
    let tempCartData = [];
    // Start setting order by getting models of earpieces selected for each ear
    let order = {
      left: find(iteModelTypeCodes, { Code: iteModelForOrder.left }, {}),
      right: find(iteModelTypeCodes, { Code: iteModelForOrder.right }, {}),
    };

    //Set configured data for each ear
    if (rightSelected) {
      //make copy of item right object to stop saving over data in redux
      let tempOrderItemRight = { ...order.right };
      //Configuring Custom Options from array of options selected by paring them with the category name.
      let tempArrayOfCustomOptions = get(iteCustomOptionsForOrder, "right", []);
      let finalObjectOfCustomOptions = {};
      tempArrayOfCustomOptions.forEach((option, index) => {
        let optionName = get(customOptionsGroupRight[index], "Name", "N/A");
        finalObjectOfCustomOptions[`${optionName}`] = option;
      });

      // Set options for earImpression to cart data of item
      let EarImpression = "";
      if (earImpressionForOrder.right) {
        if (earImpressionForOrder.right.type === "impressionOnFile") {
          EarImpression = earImpressionForOrder.right.serialNumber;
        } else if (earImpressionForOrder.right.type === "mailImpression") {
          EarImpression = "Mail in an impression";
        } else if (earImpressionForOrder.right.type === "scanAttached") {
          EarImpression = "Use the scan attached";
        }
      }

      let tempShellColor = "";
      let tempShellColorName = "";
      if (activeRightITEShellColour) {
        tempShellColor = activeRightITEShellColour.Code
        tempShellColorName = activeRightITEShellColour.Name
      }
      let tempShellStyle = "";
      if (selectedShellStyleRight) {
        tempShellStyle = selectedShellStyleRight
        if (selectedShellStyleRight.Code) {
          tempShellStyle = selectedShellStyleRight.Code;
        }
      }
      let tempRightITEFaceplate = "";
      let tempRightITEFaceplateName = "";
      if (activeRightITEFaceplate) {
        tempRightITEFaceplate = activeRightITEFaceplate.Code
        tempRightITEFaceplateName = activeRightITEFaceplate.Name
      }
      //Configuring Configuration Group from selected items.
      let tempConfigurationGroup = {
        FaceplateColour: tempRightITEFaceplate,
        Receiver: activeRightEarPiecesReceiver,
        Colours: coloursRight,
        Lengths: lengthsRight,
        PowerLevel: powerLevelRight,
        ShellColour: tempShellColor,
        ShellStyle: tempShellStyle,
        ShellColourName: tempShellColorName,
        FaceplateColourName: tempRightITEFaceplateName,
      };

      // Sets all selected settings for right ear item in cart
      var addValuesForOrderRight = {
        Quantity: 1,
        ItemCode: tempOrderItemRight.Code,
        Side: "R",
        ConfigurationGroup: tempConfigurationGroup,
        CustomOptions: finalObjectOfCustomOptions,
        EarImpression: EarImpression,
        Type: "Standard",
      };
      merge(tempOrderItemRight, addValuesForOrderRight);

      // Adds item to tempCartData before sending to redux
      tempCartData.push(tempOrderItemRight);
    }

    //Adding left item to order
    if (leftSelected) {
      //make copy of item right object to stop saving over data in redux
      let tempOrderItemLeft = { ...order.left };

      //Configuring Custom Options from array of options selected by paring them with the category name.
      let tempArrayOfCustomOptions = get(iteCustomOptionsForOrder, "left", []);
      let finalObjectOfCustomOptions = {};
      tempArrayOfCustomOptions.forEach((option, index) => {
        let optionName = get(customOptionsGroupLeft[index], "Name", "N/A");
        finalObjectOfCustomOptions[`${optionName}`] = option;
      });

      // Set options for earImpression to cart data of item
      let EarImpression = "";
      if (earImpressionForOrder.left) {
        if (earImpressionForOrder.left.type === "impressionOnFile") {
          EarImpression = earImpressionForOrder.left.serialNumber;
        } else if (earImpressionForOrder.left.type === "mailImpression") {
          EarImpression = "Mail in an impression";
        } else if (earImpressionForOrder.left.type === "scanAttached") {
          EarImpression = "Use the scan attached";
        }
      }

      let tempShellColor = "";
      let tempShellColorName = "";
      if (activeLeftITEShellColour) {

        tempShellColor = activeLeftITEShellColour.Code
        tempShellColorName = activeLeftITEShellColour.Name

      }
      let tempShellStyle = "";
      if (selectedShellStyleLeft) {

        tempShellStyle = selectedShellStyleLeft
        if (selectedShellStyleLeft.Code) {
          tempShellStyle = selectedShellStyleLeft.Code;
        }
      }

      let tempLeftITEFaceplate = "";
      let tempLeftITEFaceplateName = "";
      if (activeLeftITEFaceplate) {
        tempLeftITEFaceplate = activeLeftITEFaceplate.Code
        tempLeftITEFaceplateName = activeLeftITEFaceplate.Name
      }
      //Configuring Configuration Group from selected items.
      let tempConfigurationGroup = {
        FaceplateColour: tempLeftITEFaceplate,
        Receiver: activeLeftEarPiecesReceiver,
        Colours: coloursLeft,
        Lengths: lengthsLeft,
        PowerLevel: powerLevelLeft,
        ShellColour: tempShellColor,
        ShellStyle: tempShellStyle,
        ShellColourName: tempShellColorName,
        FaceplateColourName: tempLeftITEFaceplateName,
      };

      // Sets all selected settings for right ear item in cart
      var addValuesForOrderLeft = {
        Quantity: 1,
        ItemCode: tempOrderItemLeft.Code,
        Side: "L",
        ConfigurationGroup: tempConfigurationGroup,
        CustomOptions: finalObjectOfCustomOptions,
        EarImpression: EarImpression,
        Type: "Standard",
      };
      merge(tempOrderItemLeft, addValuesForOrderLeft);

      // Adds item to tempCartData before sending to redux
      tempCartData.push(tempOrderItemLeft);
    }

    setOrderItems(tempCartData);
    setCurrentCartForOrderSummary(tempCartData);
    let cartDataFromState = [];
    if (cartData) {
      cartDataFromState = cartData;
    }

    const fullCartData = [...cartDataFromState, ...tempCartData];
    setIsStockOrder(OrderStatus.REGULAR);
    addOrderToCart(fullCartData);
  };

  //SET Configuration Groups & Custom Options
  useEffect(() => {
    if (!iteModelForOrder) {
      return;
    }

    let rightModelHasChanged = false;
    let leftModelHasChanged = false;
    let modelObject;

    if (iteModelLinked) {
      setSelectedModelRight(iteModelForOrder.right)
      setSelectedModelLeft(iteModelForOrder.left)
    }

    if (!iteModelLinked) {
      rightModelHasChanged = selectedModelRight != iteModelForOrder.right;
      leftModelHasChanged = selectedModelLeft != iteModelForOrder.left;
    }

    if (rightModelHasChanged) {
      setSelectedModelRight(iteModelForOrder.right);
    }
    if (leftModelHasChanged) {
      setSelectedModelLeft(iteModelForOrder.left);
    }

    if (iteModelLinked) {
      let modelCode = "";
      if (iteModelForOrder.right) {
        modelCode = iteModelForOrder.right;
      }
      const modelObject = find(iteModelTypeCodes, { Code: modelCode }, null);
      if (modelObject) {
        loadConfigurationGroup(
          "B",
          modelObject.Code,
          configurationGroupData,
          "earmolds"
        );
        loadCustomOptionsGroup(
          "B",
          modelObject.ModelTypeCode,
          customOptionsGroupData,
        );
      }

    }
    if (iteModelForOrder.right && rightModelHasChanged) {
      const rightModelCode = iteModelForOrder.right;
      const rightModelObject = find(
        iteModelTypeCodes,
        { Code: rightModelCode },
        {}
      );
      if (rightModelObject) {
        loadCustomOptionsGroup(
          "R",
          rightModelObject.ModelTypeCode,
          customOptionsGroupData
        );
        loadConfigurationGroup(
          "R",
          rightModelObject.Code,
          configurationGroupData,
          "earmolds"
        );
      }
    }
    if (iteModelForOrder.left && leftModelHasChanged) {
      const leftModelCode = iteModelForOrder.left;
      const leftModelObject = find(
        iteModelTypeCodes,
        { Code: leftModelCode },
        {}
      );
      if (leftModelObject) {
        loadCustomOptionsGroup(
          "L",
          leftModelObject.ModelTypeCode,
          customOptionsGroupData
        );
        loadConfigurationGroup(
          "L",
          leftModelObject.Code,
          configurationGroupData,
          "earmolds"
        );
      }
    }
  }, [iteModelForOrder]);

  useEffect(() => {
    // Runs each time configurationGroupLeft or Right is updated and sets the data needed for each side for selection
    if (iteConfigurationGroupLeft) {
      const shellStyleLeft = get(iteConfigurationGroupLeft, "ShellStyles");
      setITEShellStyleLeft(shellStyleLeft);

      const ColoursLeft = get(iteConfigurationGroupLeft, "Colours");
      setITEShellColourLeft(ColoursLeft);

      const LengthsLeft = get(iteConfigurationGroupLeft, "Lengths");
      setLengthsLeft(LengthsLeft);

      const shellColourLeft = get(iteConfigurationGroupLeft, "ShellColours");
      setITEShellColourLeft(shellColourLeft);

      const faceplateColourLeft = get(
        iteConfigurationGroupLeft,
        "FaceplateColours"
      );
      setITEFaceplateColourLeft(faceplateColourLeft);
    }
    if (iteConfigurationGroupRight) {
      const shellStyleRight = get(iteConfigurationGroupRight, "ShellStyles");
      setITEShellStyleRight(shellStyleRight);

      const ColoursRight = get(iteConfigurationGroupRight, "Colours");
      setITEShellColourRight(ColoursRight);

      const LengthsRight = get(iteConfigurationGroupRight, "Lengths");
      setLengthsRight(LengthsRight);

      const shellColourRight = get(iteConfigurationGroupRight, "ShellColours");
      setITEShellColourRight(shellColourRight);

      const faceplateColourRight = get(
        iteConfigurationGroupRight,
        "FaceplateColours"
      );
      setITEFaceplateColourRight(faceplateColourRight);
    }
  }, [iteConfigurationGroupLeft, iteConfigurationGroupRight, orderBuilderData, iteModelForOrder, selectedModel]);

  return (
    <div className="order-builder-main-div">
      <h1>Ear Pieces</h1>
      <div>
        <div>
          <SelectSides
            leftSelected={leftSelected}
            setLeftSelected={setLeftSelected}
            rightSelected={rightSelected}
            setRightSelectedSides={setRightSelectedSides}
          />
          {(leftSelected || rightSelected) && (
            <EarImpression
              leftSelected={leftSelected}
              rightSelected={rightSelected}
              setEarImpressionForOrder={setEarImpressionForOrder}
            />
          )}
          {orderBuilderStatus === OrderBuilderStatus.LOADING && <Loading />}
          {orderBuilderStatus === OrderBuilderStatus.SUCCESS && (
            <div>
              <ITEModel
                iteModelTypeCodes={iteModelTypeCodes}
                setITEModelForOrder={setITEModelForOrder}
                leftSelected={leftSelected}
                rightSelected={rightSelected}
                iteModelLinked={iteModelLinked}
                setITEModelLinked={setITEModelLinked}
              />
              {(iteShellStyleLeft || iteShellStyleRight) && (
                <ITEShellStyle
                  iteShellStyleLeft={iteShellStyleLeft}
                  iteShellStyleRight={iteShellStyleRight}
                  selectedShellStyleRight={selectedShellStyleRight}
                  setSelectedShellStyleRight={setSelectedShellStyleRight}
                  selectedShellStyleLeft={selectedShellStyleLeft}
                  setSelectedShellStyleLeft={setSelectedShellStyleLeft}
                  leftSelected={leftSelected}
                  rightSelected={rightSelected}
                  iteModelLinked={iteModelLinked}
                  selectedModel={selectedModel}
                />
              )}
              {(iteShellColourLeft || iteShellColourRight) && (
                <ITEShellColour
                  iteShellColourRight={iteShellColourRight}
                  iteShellColourLeft={iteShellColourLeft}
                  iteFaceplateColourLeft={iteFaceplateColourLeft}
                  iteFaceplateColourRight={iteFaceplateColourRight}
                  activeRightITEShellColour={activeRightITEShellColour}
                  setActiveRightITEShellColour={setActiveRightITEShellColour}
                  activeLeftITEShellColour={activeLeftITEShellColour}
                  setActiveLeftITEShellColour={setActiveLeftITEShellColour}
                  activeRightITEFaceplate={activeRightITEFaceplate}
                  setActiveRightITEFaceplate={setActiveRightITEFaceplate}
                  activeLeftITEFaceplate={activeLeftITEFaceplate}
                  setActiveLeftITEFaceplate={setActiveLeftITEFaceplate}
                  leftSelected={leftSelected}
                  rightSelected={rightSelected}
                  selectedShellStyleRight={selectedShellStyleRight}
                  selectedShellStyleLeft={selectedShellStyleLeft}
                  iteModelLinked={iteModelLinked}
                />
              )}
              {(customOptionsGroupLeft || customOptionsGroupRight) && (
                <ITECustomOptions
                  customOptionsGroupLeft={customOptionsGroupLeft}
                  customOptionsGroupRight={customOptionsGroupRight}
                  setITECustomOptionsForOrder={setITECustomOptionsForOrder}
                  leftSelected={leftSelected}
                  rightSelected={rightSelected}
                  iteModelLinked={iteModelLinked}
                  manualVent={false}
                />
              )}
              {errorMessages && errorMessages.length > 0 && (
                <Row className="order-builder-component-title mt-2">
                  <Col xs={12} className="alert alert-danger">
                    <p>Please resolve the following errors</p>
                    <ul>
                      {errorMessages.map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              )}
              <div className="item-builder-add-to-cart-button">
                <a className="btn float-right" onClick={startAddOrderToCart}>
                  Add to order
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomHearingItemBuilder;