
import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { getProductsData } from '../../modules/storeHome/store/selectors';
import { filter } from "lodash";

const NavbarLinks = ({
	productsData
}) => {
	const [tempProductsData, setTempProductsData] = useState([]);

	useEffect(() => {
		if (productsData) {
			const validProducts = filter(productsData, (product) => product != null && product.Type);
			setTempProductsData(validProducts)
		}
	}, [productsData])

	return (
		<Navbar>
			<Container>
				<Nav className="me-auto">
					{(tempProductsData && tempProductsData.length > 0) && (
						tempProductsData.map((product, index) => {
							return (
								<Link key={index} className={`nav-link ${index == 0 ? "nav-link-left" : ""}`} to={(product.Name == "BTE/RICs" || product.Name == "ITEs" || product.Name == "Stock Orders") ? `/store/${product.Code}`: `/store/${product.Code}/${product.Type}`}>
									{product.Name} {(product.Name == "BTE/RICs" || product.Name == "ITEs") ? "Hearing Aids" : ""}
								</Link>
							)
						})
					)}
					{/* <Link className="nav-link" to="/store/PH001002">
						ITE Hearing Aids
					</Link>
					<Link className="nav-link" to="/item-builder/PH007/earmolds">
						Ear Pieces
					</Link> */}
					{/* <Link className="nav-link" to="/">
						Accessories
					</Link> */}
				</Nav>
				<Nav className="justify-content-end">
					<Link className="nav-link" to="/admin/organisation">
						Organisations
					</Link>
					<Link className="nav-link" to="/admin/adminusers">
						Admin Users
					</Link>
				</Nav>
			</Container>
		</Navbar>
	);
}


const mapStateToProps = (state) => ({
	productsData: getProductsData(state),
});

export default connect(mapStateToProps)(NavbarLinks);