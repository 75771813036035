import { connect } from 'react-redux';

import OrderConfirmation from './repairCheckout';
import { getOrderConfirmationStatus, getOrderConfirmationData, getSentOrderConfirmationStatus, getOrderNumber } from '../orderConfirmation/store/selectors';
import { getCartData } from '../orderBuilder/store/selectors';
import { getProfileData } from '../login/store/selectors';
import { addOrderToCart } from '../orderBuilder/store/action-creators';
import { submitRepairOrder, } from './store/action-creators';
import { loadOrderConfirmation, resetOrderNumber } from '../orderConfirmation/store/action-creators';


const mapStateToProps = (state) => ({
  orderConfirmationStatus: getOrderConfirmationStatus(state),
  orderConfirmationData: getOrderConfirmationData(state),
  sentOrderConfirmationStatus: getSentOrderConfirmationStatus(state),
  orderNumber: getOrderNumber(state),
  cartData: getCartData(state),
  profileData: getProfileData(state),
});

const mapDispatchToProps = {
  submitRepairOrder,
  addOrderToCart,
  loadOrderConfirmation,
  resetOrderNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);