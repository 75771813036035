import { connect } from 'react-redux';

import StockOrders from './stockOrders';
import { getProductsStatus, getProductsData, getIsStockOrder } from '../../store/selectors';
import { getCartData } from '../../../orderBuilder/store/selectors';
import { loadProducts, setIsStockOrder } from '../../store/action-creators';
import { addOrderToCart } from '../../../orderBuilder/store/action-creators';

const mapStateToProps = (state) => ({
  productsStatus: getProductsStatus(state),
  productsData: getProductsData(state),
  cartData: getCartData(state),
  isStockOrder: getIsStockOrder(state),
});

const mapDispatchToProps = {
  loadProducts,
  addOrderToCart,
  setIsStockOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(StockOrders);