import React, { useEffect, useState } from "react";
import config from '../../../../config.js'
import PropTypes from "prop-types";
import ProductCard from "../../components/ProductCard/ProductCard";
import { Container, Row, Col } from "react-bootstrap";
import RICBTEHearingAids from "../../../../assets/img/RICBTE HEaring Aids image.png";
import ITEDevice from "../../../../assets/img/ITE Device.png";
import EarPieces from "../../../../assets/img/Ear Pieces.png";
import { Link } from "react-router-dom";
import { sortBy, filter } from "lodash";
import(/* webpackChunkName: "top-level-category" */ "./topLevelCategory.scss");

const TopLevelCategory = ({ loadProducts, productsStatus, productsData }) => {
  const [validData, setValidData] = useState([])
  useEffect(() => {
    if (loadProducts) {
      loadProducts("");
    }
  }, [loadProducts]);

  useEffect(() => {
    if (productsData) {
      const validProducts = filter(productsData, (product) => product != null && product.Type);
      setValidData(validProducts);
      //console.log("sorted by - ", sortBy(productsData, [function(o) { return o.Type; }]));
    }
  }, [productsData]);

  return (
    <Col>
      <div className="top-level-category-main-div">
        <div className="top-level-category-content">
          {config.siteName && config.siteName === "PHONAK" &&
            <div>
              <h1>Welcome to the Phonak NZ eStore!</h1>
              <p>The intuitive work flow enables you to easily place orders for:</p>
              <ul>
                <li>
                  RIC and BTE hearing instruments with external receivers /
                  SlimTubing and domes
                </li>
                <li>RIC and BTE hearing instruments with custom ear pieces</li>
                <li>Custom ear pieces</li>
                <li>ITE hearing instruments</li>
                <li>
                  Chargers, and any accessories and/or Roger required to accompany
                  your hearing instrument fittings.
                </li>
              </ul>

              <p>Happy ordering!</p>
              <p>Together we change lives</p>
            </div>
          }
          {
            config.siteName && config.siteName === "UNITRON" &&
            <div>
              <h1>Welcome to the Unitron NZ eStore!</h1>
              <p>The intuitive work flow enables you to easily place orders for:</p>
              <ul>
                <li>
                  RIC and BTE hearing instruments with external receivers /
                  SlimTubing and domes
                </li>
                <li>RIC and BTE hearing instruments with custom ear pieces</li>
                <li>Custom ear pieces</li>
                <li>ITE hearing instruments</li>
                <li>Chargers and any accessories required to accompany your hearing instrument fittings.</li>
              </ul>

              <p>Happy ordering!</p>
              <p>Enjoy the experience</p>
            </div>
          }
        </div>
        <Row className="top-level-category-cards">
          {validData &&
            validData.length > 0 &&
            validData.map((product, index) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  xl={4}
                  key={index}
                  className={`${index % 3 == 0 ? "" : "card-left-border"} my-3`}
                >
                  <Link
                    to={
                      (product.Name == "BTE/RICs" || product.Name == "ITEs" || product.Name == "Stock Orders")
                        ? `/store/${product.Code}`
                        : `/store/${product.Code}/${product.Type}`
                    }
                  >
                    <div className="top-level-card">
                      <div className="top-level-img">
                        {/* <span className="helper"></span> */}
                        <img src={product.ImagePath} />
                      </div>
                      <div>
                        <h2 className="top-level-category-title">
                          {product.Name}
                        </h2>
                        <p className="btn">Order Now</p>
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            })}
          {/* <Col xs={12} md={6} xl={4} className="middle-top-level-card">
            <Link to="/store/PH001002">
              <div className="top-level-card">
                <div className="top-level-img">
                  <span className="helper"></span>
                  <img src={ITEDevice} />
                </div>
                <div>
                  <h2 className="top-level-category-title">ITE</h2>
                  <p className="btn">Order Now</p>
                </div>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Link to="/item-builder/PH007/earmolds">
              <div className="top-level-card top-level-card-right">
                <div className="top-level-img">
                  <span className="helper"></span>
                  <img src={EarPieces} />
                </div>
                <div>
                  <h2 className="top-level-category-title">Ear Pieces</h2>
                  <p className="btn">Order Now</p>
                </div>
              </div>
            </Link>
          </Col> */}
        </Row>
      </div>
    </Col>
  );
};

TopLevelCategory.propTypes = {
  loadProducts: PropTypes.func,
  productsStatus: PropTypes.string,
  productsData: PropTypes.array,
};

export default TopLevelCategory;
