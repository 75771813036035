import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Table, Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import moment from 'moment';
import { get, isEmpty, some } from "lodash";
import { OrderDetailStatus } from "./store/constants";
import config from '../../config.js';
import { fileDowload } from "./store/sagas.js";
import RepairOrderDetail from "./components/RepairOrderDetail.js";

import(/* webpackChunkName: "orderDetail" */ "./orderDetail.scss");

const OrderDetail = ({
  loadOrderDetail,
  fileDownload,
  orderDetailStatus,
  orderDetailData,
  profileData,
}) => {
  const { orderId } = useParams();
  const [orderItems, setOrderItems] = useState([]);
  const [orderDate, setOrderDate] = useState("");
  const [clientName, setClientName] = useState("");
  const [siteName, setSiteName] = useState("");

  const [orderType, setOrderType] = useState("");
  const [status, setStatus] = useState();
  const [statusDate, setStatusDate] = useState();
  const [statusNotes, setStatusNotes] = useState();
  const [files, setFiles] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  const [showOrderDetailsSection, setShowOrderDetailsSection] = useState(false);

  useEffect(() => {
    if (loadOrderDetail) {
      loadOrderDetail(orderId);
    }
  }, [loadOrderDetail]);

  useEffect(() => {
    if (orderDetailData) {
      console.log("Order Items", orderDetailData);
      const tempOrderItems = get(orderDetailData, "OrderItems", [])
      setOrderItems(tempOrderItems);
      setOrderDate(orderDetailData.OrderDate);
      setClientName(orderDetailData.ClientFullName);
      setSiteName(orderDetailData.SiteName);

      setStatus(orderDetailData.Status);
      setStatusDate(orderDetailData.StatusDate);
      setStatusNotes(orderDetailData.StatusNotes);
      setOrderType(orderDetailData.OrderType);

      setFiles(orderDetailData.Files);
    }
  }, [orderDetailData]);

  useEffect(() => {
    const hasValues = some(orderItems, item => {
      return item.ConfigurationGroup != null || item.CustomOptions != null;
    });

    if (hasValues) {
      setShowOrderDetailsSection(true);
    }
  }, [orderItems]);

  if (orderDetailStatus === OrderDetailStatus.LOADING) {
    return (
      <Col>
        <div className="">
          <Loading />
        </div>
      </Col>
    );
  }

  const startPrint = () => {
    //console.log("PRINT")
    window.print();
    return false;
  }

  // const ProductDetailModal = (props) => {
  //   //console.log("modalData", modalData);
  //   let configGroup = {};
  //   let customOptions = {};
  //   if(modalData.ConfigurationGroup) {
  //     configGroup = JSON.parse(modalData.ConfigurationGroup);
  //   }
  //   if(modalData.CustomOptions) {
  //     customOptions = JSON.parse(modalData.CustomOptions);
  //   }
  //   const productDetails = {
  //     ...configGroup,
  //     ...customOptions
  //   };
  //   //("productDetails", productDetails);
  //   return (
  //     <Modal
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">
  //         {modalData.ItemName} {modalData.Side}
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <h4>Product Summary</h4>
  //         <table>
  //           <tr>
  //               <th>Special Instructions</th>
  //               <td>{modalData.SpecialInstructions}</td>
  //           </tr>
  //         {Object.entries(productDetails).map(([key, value]) => {
  //           if (value === "" || value === null) {
  //               return null;
  //           }
  //           return (
  //               <tr key={key}>
  //                   <th>{key}</th>
  //                   <td>{value}</td>
  //               </tr>
  //           )}
  //         )}
  //         </table>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={props.onHide}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }

  return (
    <div className="orderDetail-title">
      <div className="order-info">
        <Row>
          <Col>
            <h1>Order info</h1>
          </Col>
          <Col>
            <button onClick={startPrint} className="notprintable float-right btn btn-primary">Print</button>
          </Col>
        </Row>
        <table>
          <tr>
            <th className="address-header">Address</th>
            <td>
              {config.siteName && config.siteName === "UNITRON" && "Unitron New Zealand"}
              {config.siteName && config.siteName === "PHONAK" && "Phonak New Zealand"}
              <br />Level 2, The Chester Building<br />
              28 The Warehouse Way<br />
              Northcote<br />
              Auckland 0627
            </td>
          </tr>
          <tr>
            <th>Order Number</th>
            <td>{orderId}</td>
          </tr>
          <tr>
            <th>Order Date</th>
            <td>{moment(orderDate).format('MMMM Do YYYY, h:mm a')}</td>
          </tr>
          <tr>
            <th>Client's Name</th>
            <td>{clientName}</td>
          </tr>
          <tr>
            <th>Site</th>
            <td>{siteName}</td>
          </tr>
        </table>
      </div>
      <div className="order-history">
        <h2>Order Summary</h2>
        <hr />
        <div>
          <table>
            <tr>
              <th>ITEMS FOR:</th>
              <td>{profileData && profileData.FirstName} {profileData && profileData.LastName}</td>
            </tr>
            {status &&
              <tr>
                <th>Order Status:</th>
                <td>{status}</td>
              </tr>
            }
            {statusDate &&
              <tr>
                <th>Status Date:</th>
                <td>{moment(statusDate).format('MMMM Do YYYY, h:mm a')}</td>
              </tr>
            }
            {statusNotes &&
              <tr>
                <th>Status Notes:</th>
                <td>{statusNotes}</td>
              </tr>
            }
            {orderType &&
              <tr>
                <th>Order Type:</th>
                <td>{orderType}</td>
              </tr>
            }
            {files && files.length > 0 &&
              (
                <tr>
                  <th>Files:</th>
                  <td>
                    <p>
                      {files.map((file) => {
                        return (
                          <span><a onClick={(e) => {
                            e.preventDefault();
                            fileDownload(orderId, file);
                          }} href="#">{file}</a>, </span>
                        )
                      })}
                    </p>
                  </td>
                </tr>
              )
            }

          </table>
        </div>
        <br />
        <Row>
          <Col xs={12}>

            {/* {orderItems &&
          orderItems.map((orderItem, index) => {
            return (
              <Col key={index} md={6} xs={12}>
                <div>
                  <p>{orderItem.Name}</p>
                  <p>Item Code: {orderItem.ItemCode}</p>
                  <p>Colour: {orderItem.ColourCode}</p>
                </div>
              </Col>
            );
          })} */}
            {orderItems &&
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Product Code</th>
                    <th>Quantity</th>
                    {/* <th>Item Details</th> */}
                  </tr>
                </thead>
                <tbody>
                  {orderItems.map((orderItem, index) => {
                    return (
                      <tr key={index}>
                        <th>{orderItem.ItemName} {orderItem.Side}</th>
                        <td>
                          {orderItem.ItemCode}
                        </td>
                        <td>
                          {orderItem.Quantity
                            ? `${orderItem.Quantity}`
                            : "1"}
                        </td>
                        {/* <td>
                          <Button variant="primary" onClick={() => {
                            setModalData(orderItem)
                            setModalShow(true)
                          }}>
                            See Item Details
                          </Button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
            {orderType && orderType == "RepairRemake" &&
              <div>
                <h2>Submitted Repair / Remake Form</h2>
                <hr />
                <RepairOrderDetail data={orderDetailData} />
              </div>
            }
          </Col>

          {showOrderDetailsSection &&
            <Col xs={12}>
              <h2>Order Detail</h2>
              <hr />
              {orderItems &&
                orderItems.map((orderItem, index) => {
                  if (orderItem.ConfigurationGroup == null && orderItems.CustomOptions == null) {

                    return;
                  }
                  let configGroup = {};
                  let customOptions = {};
                  if (orderItem.ConfigurationGroup) {
                    configGroup = JSON.parse(orderItem.ConfigurationGroup);
                  }
                  if (orderItem.CustomOptions) {
                    customOptions = JSON.parse(orderItem.CustomOptions);
                  }
                  const productDetails = {
                    ...configGroup,
                    ...customOptions
                  };
                  return (
                    <div>
                      <Row>
                        <Col xs={3}>
                          <h3>{orderItem.ItemName} {orderItem.Side}:</h3>
                        </Col>
                        <Col xs={9}>

                          {Object.entries(productDetails).map(([key, value]) => {
                            if (value === "" || value === null) {
                              return null;
                            }
                            return (
                              <div key={key}>
                                <p><strong>{key}</strong>: {value}</p>
                              </div>
                            )
                          }
                          )}
                          {orderItem.SpecialInstructions &&
                            <p><strong>Special Instructions</strong>: {orderItem.SpecialInstructions}</p>
                          }
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  )
                })}

            </Col>
          }
        </Row>
      </div>
      {/* <ProductDetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
    </div>
  );
};


OrderDetail.propTypes = {
  loadOrderDetail: PropTypes.func,
  fileDowload: PropTypes.func,
  orderDetailStatus: PropTypes.string,
  orderDetailData: PropTypes.object,
  profileData: PropTypes.object,
};

export default OrderDetail;
